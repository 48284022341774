<template>
    <div>
        <ek-table
            :columns="columns"
            :items="chapterList"
            @details="chapterDetails"
            @delete-selected="deleteChapters"
        >
            <template slot="items.isFree" slot-scope="{ value }">
                <StatusBadge :value="value" :options="isFreeStatus" />
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";

export default {
    components: {
        StatusBadge,
    },
    computed: {
        ...mapState({
            chapterList: (state) => state.chapter.chapterList,
            columns: (state) => state.chapter.columns,
            isFreeStatus: (state) => state.chapter.isFreeStatus,
        }),
    },
    methods: {
        deleteChapters(e) {
            this.deleteRangeChapters(e);
        },
        ...mapActions([
            "getAllChapters",
            "getTeachersList",
            "getByIdChaptert",
            "deleteRangeChapters",
        ]),
        chapterDetails({ row }) {
            // console.log(row);
            this.$router.push(`/admin/chapter/${row.id}`);
        },
    },
    created() {
        this.getAllChapters();
        this.getTeachersList();
    },
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
